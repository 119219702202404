function AppIcons () {
  const elements = {}

  const initializeSelectors = () => {
    elements.defaultBox = $('#default')
    elements.durationDiv = $('#duration_field')
    elements.startDateBox = $('#start_date')
    elements.endDateBox = $('#end_date')
    elements.activeBox = $('#active')
    elements.submit = $('#submit')
    elements.appIconId = $('#app-icon-id')
    elements.defaultIcon = ''
  }

  const initializeListeners = () => {
    elements.defaultBox.on('change', toggleFields)
    elements.submit.on('click', submitForm)
  }

  function toggleFields () {
    elements.durationDiv.toggle(!elements.defaultBox.prop('checked'))

    elements.startDateBox.prop('disabled', elements.defaultBox.prop('checked'))
    elements.startDateBox.prop('required', !elements.defaultBox.prop('checked'))

    elements.endDateBox.prop('disabled', elements.defaultBox.prop('checked'))
    elements.endDateBox.prop('required', !elements.defaultBox.prop('checked'))

    elements.activeBox.prop('checked', elements.defaultBox.prop('checked'))
    elements.activeBox.prop('disabled', elements.defaultBox.prop('checked'))
  }

  function loadDatePickerRules () {
    const editAction = window.location.pathname.includes('edit')
    const date = new Date()
    const monthName = date.toLocaleString('default', { month: 'long' })
    const defaultStartDate = elements.startDateBox.val()

    elements.startDateBox.datetimepicker({
      format: 'YYYY-MM-DD hh:mm A',
      minDate: defaultStartDate || date,
      defaultDate: defaultStartDate || date
    })

    elements.endDateBox.datetimepicker({
      format: 'YYYY-MM-DD hh:mm A',
      useCurrent: false,
      minDate: editAction ? elements.startDateBox.val() : date,
      defaultDate: new Date(`${monthName} ${date.getDate()}, ${date.getFullYear()} 23:59:00`)
    })

    elements.endDateBox.on('dp.change', function (e) {
      elements.startDateBox.data('DateTimePicker').maxDate(e.date)
    })

    if (editAction) {
      elements.startDateBox.data('DateTimePicker').maxDate(elements.endDateBox.val())
    }
  }


  function submitForm (e) {
    if (elements.defaultBox.prop('checked') && elements.defaultIcon !== '') {
      alert(`${elements.defaultIcon} is already the default icon`)
      e.preventDefault()
    } else {
      elements.activeBox.prop('disabled', false)
    }
  }

  if (window.location.pathname.includes('app_icons')) {
    initializeSelectors()
    initializeListeners()
    loadDatePickerRules()

    if (elements.defaultBox.prop('checked')) {
      toggleFields()
    }
  }
}

export default AppIcons
